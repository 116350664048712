<template>
  <div>
    <el-form :model="form"
             label-width="50px"
             style="margin-bottom:10px">
      <el-row :gutter="10">
        <el-col :lg="3"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-form-item label="版块:">
            <el-select v-model="form.discuss_board"
                       @change="search"
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in plateList"
                         :key="item.board_id"
                         :label="item.board_name"
                         :value="item.board_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :lg="3"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-select v-model="form.discuss_post_type"
                     style="width:100%"
                     @change="search"
                     placeholder="请选择">
            <el-option v-for="item in types"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :lg="3"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-input clearable
                    placeholder="请输入内容"
                    v-model="form.search"
                    @keyup.enter.native="search"
                    style="width:100%" />
        </el-col>
        <el-col :lg="15"
                :md="24"
                :sm="12"
                :xl="12"
                :xs="24">
          <el-button type="primary"
                     @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/audit.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        discuss_board: 0,
        search: ''
      },
      plateList: [],
      tableData: [],
      pageName: 'cz_answer_audit_admin',
      tableColumns: [
        { prop: 'discuss_id', align: 'center', label: 'ID', width: '', showToopic: false },
        { prop: 'board_name', align: 'center', label: '版块', width: '', showToopic: false },
        { prop: 'discuss_title', align: 'center', label: '标题', width: '', showToopic: false },
        { prop: 'discuss_content', align: 'center', label: '内容', width: '', showToopic: false },
        { prop: 'discuss_time', align: 'center', label: '发帖时间', width: '', showToopic: false, sortable: true },
        { prop: 'user_id', align: 'center', label: '发帖人ID', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'text', btns: [
            { name: '审核', class: 'text_btn_primary', event: 'toAudit' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.getPlateList()
    this.setTablesColums()
  },
  components: {
    Form
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v1/discuss/review',
        method: 'get',
        params: form
      }).then(res => {
        this.totalElements = res.data.count
        this.tableData = res.data.list
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    getPlateList () {
      this.$http({
        url: '/api/v1/discuss/board_lst',
        method: 'get',
      }).then(res => {
        this.plateList1 = JSON.parse(JSON.stringify(res.data.list))
        this.plateList = res.data.list[0] = { board_id: 0, board_name: '全部' }
        this.plateList = res.data.list
      })
    },
    toAudit (row) {
      // let form = JSON.parse(JSON.stringify(row))
      // this.$refs.FormRef.form = form
      // this.$refs.FormRef.dialogVisible = true

      this.$http({
        url: '/api/v1/discuss/audit',
        method: 'get',
        params: {
          id: row.discuss_id
        }
      }).then(res => {
        console.log('res', res);
        this.$refs.FormRef.form = res.data.list
        this.$refs.FormRef.id = row.discuss_id
        this.$refs.FormRef.reason = ''
        this.$refs.FormRef.dialogVisible = true
      })
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>