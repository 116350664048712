<template>
  <el-dialog title="审核帖子"
             :visible.sync="dialogVisible"
             v-dialogDrag
             :close-on-click-modal="false"
             :append-to-body="true"
             width="800px"
             :before-close="handleClose">

    <div class="card"
         v-if="dialogVisible">
      <div class="card_warp">
        <div class="header">{{form.subject.discuss_title}}</div>
        <div class="info">
          发帖人：{{form.subject.user_id}}
          {{form.subject.is_edu_user == 0 ?'学生':'教师'}} <span style="margin:0 5px">|</span>
          发帖时间：{{form.subject.discuss_time}}
        </div>
        <div style="font-size:16px; font-weight:bold;padding:20px 0;border-bottom: 1px solid #e8eaec;">
          {{form.subject.discuss_content}}
        </div>

        <div class="images">
          <div>
            <el-row :gutter="10">
              <el-image style="width: 100px; height: 100px;border-radius: 5px; margin:10px"
                        v-for="item in form.subject.discuss_image"
                        :key="item"
                        :src="item"
                        :preview-src-list="form.subject.discuss_image">
              </el-image>
            </el-row>
          </div>
        </div>

      </div>
    </div>
    <div class="info"
         v-if="form.common">
      回复人：{{form.common.user_id}}
      {{form.common.is_edu_user == 0 ?'学生':'教师'}} <span style="margin:0 5px">|</span>
      发帖时间：{{form.common.discuss_time}}
    </div>
    <div v-if="form.common"
         class="huifu">
      <div class="one">
        内容：<div v-html="form.common.discuss_content"></div>
      </div>
    </div>
    <div class="images">
      <div>
        <el-row :gutter="10">
          <el-image style="width: 100px; height: 100px;border-radius: 5px; margin:10px"
                    v-for="item in form.common.discuss_image"
                    :key="item"
                    :src="item"
                    :preview-src-list="form.common.discuss_image">
          </el-image>
        </el-row>
      </div>
    </div>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="shoInner">不通过</el-button>
      <el-button type="primary"
                 @click="ispass(1)">通过</el-button>
    </span>
    <el-dialog width="350px"
               title="驳回原因"
               :visible.sync="innerVisible"
               append-to-body>
      <el-form ref="bohuiFormRef"
               label-width="40px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="原因">
              <el-input clearable
                        type="textarea"
                        :rows="4"
                        placeholder="请输入驳回"
                        v-model="reason" />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :gutter="10"
                style="text-align:center">
          <el-button type="primary"
                     @click="ispass(-1)">提交</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      innerVisible: false,
      form: {},
      id: '',
      count: 0,
      reason: ''
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    ispass (num) {
      if (num == '-1' && this.reason == '') {
        this.$message.warning('请输入驳回原因')
        return
      }
      this.$http({
        url: '/api/v1/discuss/passed',
        method: 'post',
        data: {
          discuss_id: this.id,
          type: num,
          reason: this.reason
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.innerVisible = false
        this.handleClose()
        this.$parent.refresh()
      })

    },
    shoInner () {
      this.innerVisible = true
    },
    // getInfo () {
    //   this.$http({
    //     url: '/api/v1/discuss/shenhe',
    //     method: 'get',
    //     params: {
    //       id: this.form.discuss_id
    //     }
    //   }).then(res => {
    //     console.log('res', res);
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  font-weight: bold;
  color: black;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.card {
  .card_warp {
    background: #ffffff;
    position: relative;
    overflow: hidden;
    .fixed_right_btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .header {
      font-size: 18px;
      line-height: 40px;
      // padding: 10px;
      padding-top: 0;
      font-weight: bold;
    }
    .info {
      font-size: 15px;
      // text-indent: 1em;
      color: #aaacae;
      border-bottom: 1px solid #e8eaec;
      padding-bottom: 10px;
    }
    .topic_warp {
      text-indent: 1em;
      padding: 10px;
      font-size: 16px;
    }
    .show_btn {
      display: flex;
      justify-content: center;
      i {
        font-size: 16px;
      }
    }
    .topic {
      .line {
        margin: 10px 0;
        padding: 10px;
        // border-bottom: 1px solid #f0f2f5;
        .line_border_bottom {
          width: 98%;
          height: 10px;
          border-bottom: 1px solid #c1c1c1;
          margin: 0 auto;
        }
        div {
          text-indent: 1em;
        }
      }
      .tou {
        display: inline-block;
        text-indent: 1em;
        font-size: 16px;
        font-weight: bold;
        margin: 10px;
        border-left: 4px solid #409eff;
      }
    }
  }
}
.huifu {
  width: 100%;
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}
.tou {
  display: inline-block;
  // text-indent: 1em;
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  // border-left: 4px solid #409eff;
}

::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>